<!-- 车辆管理 -->
<template>
  <div>
    <el-card>
      <div class="topsearch">
        <div class="display">
          <div>关键词</div>
          <el-input
            size="small"
            placeholder="名称/车辆名称"
            v-model="ruleForm.keyword"
          >
          </el-input>
        </div>
        <div class="display">
          <div>车辆类型</div>
          <el-cascader
            :options="carStatusList"
            @change="carStatusChange"
            v-model="carValue"
            :props="optionProps"
            size="small"
            style="width: 50%"
            ref="cascaderAddr"
          >
          </el-cascader>
        </div>
        <div class="display">
          <div>品牌</div>
          <el-select
            size="small"
            v-model="ruleForm.vehicleBrand"
            placeholder="品牌"
          >
            <el-option
              v-for="item in brandList"
              :key="item.id"
              :label="item.brandName"
              :value="item.id"
            >
            </el-option>
          </el-select>
        </div>
        <div class="btns">
          <el-button
            size="small"
            type="primary"
            class="submit"
            @click="inputChange"
          >
            搜索
          </el-button>
          <el-button
            size="small"
            class="submit"
            type="primary"
            @click="resetCli"
          >
            重置
          </el-button>
        </div>
      </div>
      <div class="topsearch">
        <div style="width: 55px; margin-right: 20px">所在地</div>
        <el-select
          v-model="ruleForm.shopAdressProvince"
          placeholder="请选择省"
          @change="provinceChange"
          size="small"
        >
          <el-option
            v-for="(item, index) in provinceList"
            :key="index"
            :label="item.cityName"
            :value="item.cityId"
          ></el-option>
        </el-select>
        <el-select
          v-model="ruleForm.shopAdressCity"
          placeholder="请选择市"
          @change="cityChange"
          size="small"
        >
          <el-option
            v-for="(item, index) in cityList"
            :key="index"
            :label="item.cityName"
            :value="item.cityId"
          ></el-option>
        </el-select>
        <el-select
          v-model="ruleForm.shopAdressArea"
          placeholder="请选择区"
          @change="areaChange"
          size="small"
        >
          <el-option
            v-for="(item, index) in areaList"
            :key="index"
            :label="item.cityName"
            :value="item.cityId"
          ></el-option>
        </el-select>
        <div class="status">审核状态</div>
        <el-select
          v-model="ruleForm.checkStatus"
          placeholder="审核状态"
          size="small"
        >
          <el-option
            v-for="(item, index) in checkStatusList"
            :key="index"
            :label="item.name"
            :value="item.id"
          ></el-option>
        </el-select>
        <div class="status">车辆状态</div>
        <el-select
          v-model="ruleForm.vehicleStatus"
          placeholder="车辆状态"
          size="small"
        >
          <el-option
            v-for="(item, index) in vehicleStatusList"
            :key="index"
            :label="item.name"
            :value="item.id"
          ></el-option>
        </el-select>
      </div>
      <el-table
        :header-cell-style="{ textAlign: 'center' }"
        :cell-style="{ textAlign: 'center' }"
        :data="tableData"
        height="500"
      >
        <el-table-column prop="vehicleImg" label="车辆图片" width="180">
          <template slot-scope="scope">
            <el-image
            fit="contain"
              style="width: 100px; height: 100px"
              :src="scope.row.vehicleImgs[0]"
            ></el-image>
          </template>
        </el-table-column>
        <el-table-column prop="vehicleOwnerName" label="车主名称" width="180">
        </el-table-column>
        <el-table-column prop="date" label="车辆类型" width="180">
          <template slot-scope="scope">
            <div>
              {{ scope.row.firstLevelType }}/{{ scope.row.secondLevelType }}
            </div>
          </template>
        </el-table-column>
        <el-table-column label="车辆状态" width="180">
          <template slot-scope="scope">
            <div>
              {{
                scope.row.vehicleStatus == 0
                  ? '闲置中'
                  : scope.row.vehicleStatus == 1
                  ? '工作中'
                  : '维修中'
              }}
            </div>
          </template>
        </el-table-column>
        <el-table-column label="审核状态" width="180">
          <template slot-scope="scope">
            <div>
              {{
                scope.row.checkStatus == 0
                  ? '待审核'
                  : scope.row.checkStatus == 1
                  ? '审核通过'
                  : '审核拒绝'
              }}
            </div>
          </template>
        </el-table-column>
        <el-table-column prop="vehicleModel" label="车辆型号" width="180">
        </el-table-column>
        <el-table-column prop="offer" label="车辆价格" width="180">
        </el-table-column>
        <el-table-column
          prop="vehicleBrand"
          label="车辆品牌"
          width="180"
        ></el-table-column>
        <el-table-column prop="address" label="车辆地址">
          <template slot-scope="scope">
            <div>
              {{ scope.row.vehicleProvince }}/{{ scope.row.vehicleCity }}/{{
                scope.row.vehicleCounty
              }}
            </div>
          </template>
        </el-table-column>

        <el-table-column prop="date" label="管理" width="180">
          <template slot-scope="scope">
            <div>
              <el-button size="small" @click="edit(scope)" type="primary"
                >编辑</el-button
              >
              <el-button
                size="small"
                @click="deleteCar(scope.row.id)"
                type="danger"
                >删除</el-button
              >
            </div>
          </template>
        </el-table-column>
      </el-table>
      <el-pagination
        @current-change="handleCurrentChange"
        layout="prev, pager, next"
        :total="total"
        background
      >
      </el-pagination>
    </el-card>
  </div>
</template>

<script>
import {
  getCityTree,
  getCitySelect,
  vehicleTypeTree,
  getVehicleInfoByPage,
  getBrand,
  deleteCar,
} from '@/api/user'
import { debounce } from '@/utils/validate'
export default {
  data() {
    return {
      tableData: [],
      carStatusList: [],
      carValue: [],
      brandList: [],
      optionProps: {
        value: 'vehicleTypeId',
        label: 'vehicleTypeName',
      },
      ruleForm: {
        page: 1,
        pageSize: 10,
        keyword: '',
        firstLevelType: '',
        secondLevelType: '',
        vehicleBrand: '',
        vehiclePutawayStatus: 1,
        vehicleCityCode: '',
        vehicleCountyCode: '',
        vehicleProvinceCode: '',
        vehicleStatus: '',
        checkStatus: '',
      },
      total: 0,
      provinceList: [],
      cityList: [],
      areaList: [],
      vehicleStatusList: [
        { id: 0, name: '闲置中' },
        { id: 1, name: '工作中' },
        { id: 2, name: '维修中' },
      ],
      checkStatusList: [
        { id: 0, name: '待审核' },
        { id: 1, name: '审核通过' },
        { id: 2, name: '审核拒绝' },
      ],
    }
  },
  mounted() {
    if (this.$route.query.type) {
      this.ruleForm.checkStatus = Number(this.$route.query.type)
    }
    if (this.$route.query.types) {
      this.ruleForm.vehicleStatus = Number(this.$route.query.types)
    }
    this.getVehicleInfoByPage()
    this.getCityTree()
    this.vehicleTypeTree()
    getBrand().then((res) => {
      this.brandList = res.data
    })
  },
  methods: {
    getVehicleInfoByPage() {
      getVehicleInfoByPage(this.ruleForm).then((res) => {
        this.tableData = res.data.list
        this.total = res.data.total
      })
    },
    vehicleTypeTree() {
      vehicleTypeTree().then((res) => {
        this.carStatusList = res.data
      })
    },
    carStatusChange(value) {
      this.ruleForm.firstLevelType = value[0]
      this.ruleForm.secondLevelType = value[1]
    },
    getCityTree() {
      getCityTree().then((res) => {
        this.provinceList = res.data
      })
    },
    provinceChange(e) {
      let provinceObj = {}
      provinceObj = this.provinceList.find((item) => {
        return item.cityId === e
      })
      this.ruleForm.vehicleProvinceCode = provinceObj.cityId
      getCitySelect({ cityPid: e }).then((res) => {
        this.cityList = res.data
      })
    },
    cityChange(e) {
      let cityObj = {}
      cityObj = this.cityList.find((item) => {
        return item.cityId === e
      })
      this.ruleForm.vehicleCityCode = cityObj.cityId
      getCitySelect({ cityPid: e }).then((res) => {
        this.areaList = res.data
      })
    },
    areaChange(e) {
      let countyObj = {}
      countyObj = this.areaList.find((item) => {
        return item.cityId === e
      })
      this.ruleForm.vehicleCountyCode = countyObj.cityId
    },
    handleCurrentChange(val) {
      this.ruleForm.page = val
      this.getVehicleInfoByPage()
    },
    inputChange: debounce(function () {
      this.getVehicleInfoByPage()
    }, 300),
    edit(v) {
      this.$store.commit('user/SET_FORMDATA', v.row)
      this.$router.push({
        path: '/addCar',
        query: {
          type: 2,
          id: v.row.id,
        },
      })
    },
    deleteCar(id) {
      this.$confirm('此操作将永久删除, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
      })
        .then(() => {
          deleteCar(id).then((res) => {
            this.$message.success('删除成功')
            this.getVehicleInfoByPage()
          })
        })
        .catch(() => {
          this.$message({
            type: 'info',
            message: '已取消删除',
          })
        })
    },

    // 重置
    resetCli() {
      this.ruleForm = {
        page: 1,
        pageSize: 10,
      }
      this.carValue = []
      this.getVehicleInfoByPage()
    },
  },
}
</script>
<style lang="scss" scoped>
.status_box {
  display: flex;
  align-items: center;
  margin-left: 20px;
}
.el-table {
  margin: 10px 0;
}
.el-pagination {
  text-align: right;
}
.btns {
  margin-left: auto;
  display: flex;
}
.topsearch {
  width: 100%;
  display: flex;
  align-items: center;
  .status {
    width: 80px;
    text-align: center;
    margin-right: 10px;
  }
  .display {
    display: flex;
    align-items: center;
    .el-input {
      width: 60%;
    }
    .el-select {
      width: 60%;
    }
    div {
      margin-right: 20px;
    }
  }
}

.topsearch:nth-child(2) {
  margin-top: 20px;
  .el-select {
    width: 17%;
    margin-right: 20px;
  }
}
</style>
